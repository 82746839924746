/* src/App.css */

.App {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  padding: 20px;
}

h1 {
  font-size: 2rem;
  color: #333;
}

h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #444;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

ul {
  padding-left: 20px;
}

a {
  text-decoration: none;
  color: #007BFF;
}
.texto-introducao {
  line-height: 1.8;
  margin: 20px 0;
}